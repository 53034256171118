<template>
  <v-container>
    <v-alert v-if="isLoading" type="info">Loading the list. Please wait...</v-alert>
    <v-expansion-panels v-else :multiple="true" v-model="expandedGroups">
      <v-expansion-panel v-for="({ group: label, people: subGroup }, index) in people" :key="index">
        <v-expansion-panel-header>{{ groupLabel(group) }} - {{ label }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <greatscots-card v-if="view==='card'" :people="subGroup"></greatscots-card>
          <greatscots-photo v-else-if="view==='photo'" :people="subGroup"></greatscots-photo>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
<script>
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    GreatscotsCard: () => import('./Card'),
    GreatscotsPhoto: () => import('./Photo')
  },
  props: {
    people: {
      type: Array,
      default: () => { return [] }
    },
    group: String,
    view: String,
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root }) {
    const expandedGroups = ref([])
    const groupedPeople = ref([])
    watch(() => props.people, () => {
      let hash = {}
      let arr = []
      for (let i = 0; i < props.people.length; i++) {
        let temp = props.group.split('.')
        let group = ''
        if (temp.length === 1) {
          if (props.group in props.people[i]) group = props.people[i][props.group]
        } else {
          if (temp[0] in props.people[i] && temp[1] in props.people[i][temp[0]]) group = props.people[i][temp[0]][temp[1]]
        }
        if (!(group in hash)) {
          hash[group] = arr.length
          arr.push({ label: group, people: [] })
        }
        arr[hash[group]].people.push(props.people[i])
      }
      expandedGroups.value = []
      for (let j = 0; j < arr.length; j++) {
        expandedGroups.value.push(j)
      }
      groupedPeople.value = arr
    })
    function groupLabel (group) {
      if (group === undefined) return ''
      // console.log(group)
      if (group === 'person') return 'Category'
      else if (group === 'group') return 'Group'
      let temp = group.split('.')
      if (temp[0] === 'location') return temp[1].substr(0, 1).toUpperCase() + temp[1].substr(1)
      return group
    }

    function print (win) {
      let isFirst = true
      for (let i = 0; i < props.people.length; i++) {
        let expanded = false
        for (let j = 0; j < expandedGroups.value.length; j++) {
          if (expandedGroups.value[j] === i) {
            expanded = true
            break
          }
        }
        if (expanded) {
          let section = props.people[i]
          win.document.write('<div ' + (isFirst ? '' : 'style="border-top:1px solid #CCCCCC;margin:1em 0;page-break-inside:avoid"') + '>')
          win.document.write('<h2>' + section.group + '</h2>')
          for (let j = 0; j < section.people.length; j++) {
            let item = section.people[j]
            if (props.view === 'photo') {
              win.document.write('<div style="display:inline-block;width:130px;height:180px;vertical-align:top;page-break-inside:avoid;margin-right:.5em">')
              win.document.write('<img src="' + (item.photo || process.env.VUE_APP_BASE_URL + '/img/no.jpg') + '" style="max-width:130px;max-height:150px" /><div style="width:130px;">' + item.name.first + ' ' + item.name.last + '</div>')
              win.document.write('</div>')
            } else if (props.view === 'card') {
              win.document.write('<div style="display:inline-block;width:330px;height:150px;vertical-align:top;margin:.3em;border:1px solid #CCCCCC;padding:10px">')
              win.document.write('<img src="' + (item.photo || process.env.VUE_APP_BASE_URL + '/img/no.jpg') + '" style="max-width:100px;max-height:130px;display:inline-block;vertical-align:top" />')
              win.document.write('<div style="width:220px;display:inline-block;vertical-align:top;margin-left:10px">')
              win.document.write('<h3 style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;margin:.3em 0 .4em 0;">' + item.name.first + ' ' + item.name.last + '</h3>')
              if (item.jobTitle) win.document.write('<div>' + item.jobTitle + '</div>')
              if (item.location.department) win.document.write('<div>' + item.location.department + '</div>')
              if (item.email) win.document.write('<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis">' + item.email + '</div>')
              if (item.phone) win.document.write('<div>' + item.phone + '</div>')
              if (item.location.room) win.document.write('<div>' + (item.person === 'Student' ? 'Room: ' : 'Office: ') + item.location.room + '</div>')
              win.document.write('</div>')
              win.document.write('</div>')
            }
          }
          win.document.write('</div>')
          isFirst = false
        }
      }
    }

    return {
      groupLabel,
      expandedGroups,
      groupedPeople,
      print
    }
  }
}
</script>
